exports.components = {
  "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-anthora-cup-linocut-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emilia-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/anthora-cup-linocut/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-anthora-cup-linocut-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-bakeri-painting-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emilia-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/bakeri-painting/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-bakeri-painting-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-greenpoint-coffeeshop-paintings-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emilia-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/greenpoint-coffeeshop-paintings/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-greenpoint-coffeeshop-paintings-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-greenpoint-g-train-lino-print-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emilia-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/greenpoint-g-train-lino-print/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-greenpoint-g-train-lino-print-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-greenpoint-misc-paintings-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emilia-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/greenpoint-misc-paintings/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-greenpoint-misc-paintings-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-homecoming-painting-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emilia-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/homecoming-painting/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-homecoming-painting-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-lic-pepsi-sign-lino-print-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emilia-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/lic-pepsi-sign-lino-print/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-lic-pepsi-sign-lino-print-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-paloma-painting-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emilia-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/paloma-painting/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-paloma-painting-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-peter-pan-donuts-painting-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emilia-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/peter-pan-donuts-painting/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-peter-pan-donuts-painting-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-projects-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emilia-core/src/templates/projects-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-projects-query-tsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */)
}

